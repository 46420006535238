<template>
  <div class="xs-hide">
    <div class="custom-tab-wrap">
      <div v-for="(item,index) in list"
           :key="item.name"
           :class="[active === index ? 'active': '']"
           class="custom-tab">
        {{item.name}}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CustomTab',
  props: {
    active:{
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      list: [
        {
          name: '01 SHOPPING CART'
        },
        {
          name: '02 CUSTOMER INFO'
        },
        {
          name: '03 SHIPPING INFO'
        },
        {
          name: '04 PAYMENT SELECTION'
        },
        {
          name: '05 PAYMENT RESULT'
        }
      ]
    }
  }
}
</script>

<style scoped lang="less">
.custom-tab-wrap{
  display: flex;
  margin-bottom: 24px;
}
.custom-tab {
  flex: 1;
  position: relative;
  width: 240px;
  height: 48px;
  line-height: 48px;
  text-align: center;
  color:@gray-color;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  &.active{
    color: @main-color;
    &:after{
      background-color: @main-color;
      display: block;
    }
  }
  &:after{
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%);
    width: 40px;
    height: 3px;
    background-color: @gray-color;
    display: none;
  }
}
</style>
