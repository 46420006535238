<template>
  <div class="page-wrap">
    <div class="tab">
      <custom-tab :active="0"/>
    </div>
    <div class="cart-main space-between-wrap">
      <div class="content-left">
        <div class="cart-box">
          <img class="xs-hide" src="../assets/images/cart-left.svg" alt="cart-left">
          <img class="xs-show" src="../assets/images_mobile/01.svg" alt="cart-left">
        </div>
      </div>
      <div class="content-right xs-hide">
          <div class="reative-wrap">
            <img src="../assets/images/right_account_pc.svg" alt="cart-left">
            <div class="proceed-btn transparent-btn" @click="$router.push({name: 'CustomerInfo'})">
            </div>
          </div>
          <!--
          <div class="block-puser-card">
            <img src="../assets/images/block-card.svg" alt="block-puser-card">
          </div>
          -->
      </div>
    </div>
    <base-footer @handleClickNext="next" btnTxt="Proceed to Checkout"/>
  </div>
</template>

<script>
import CustomTab from '@/components/CustomTab'
import BaseFooter from '@/components/BaseFooter'
export default {
  name: 'ShoppingCart',
  components: {
    CustomTab,
    BaseFooter
  },
  methods: {
    next() {
      this.$router.push({
        name: 'CustomerInfo'
      })
    }
  }
}
</script>

<style scoped lang="less">
  .cart-main{
    .block-puser-card{
      margin-top: 16px;
      height: 136px;
    }
    .proceed-btn{
      position: absolute;
      width: 320px;
      bottom: 48px;
      right: 32px;
    }
  }
</style>
