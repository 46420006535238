<template>
  <div class="page-wrap">
    <div class="tab">
      <custom-tab :active="3" />
    </div>
    <div class="cart-main space-between-wrap">
      <div class="content-left" @dblclick="inputAddress">
        <div class="cart-box reative-wrap">
          <div class="xs-hide">
            <img :src="url_p" alt="cart-left" />
            <div
              class="transparent-btn pre-btn"
              @click="$router.push({ name: 'ShippingInfo' })"
            ></div>
            <div class="transparent-btn next-btn" @click="Continue"></div>
          </div>
          <div class="xs-show">
            <img :src="url_m" alt="04" />
          </div>
        </div>
      </div>
      <div class="content-right">
        <div class="reative-wrap">
          <img src="../assets/images/2_4_right.svg" alt="block-puser-card" />
        </div>
      </div>
    </div>
    <base-footer @handleClickNext="Continue" btn-txt="Continue" />
  </div>
</template>

<script>
import CustomTab from "@/components/CustomTab";
import BaseFooter from "@/components/BaseFooter";
import dayjs from "dayjs";
import { Base64 } from "js-base64";
import MD5 from "crypto-js/md5";
import { test } from "@/api";

export default {
  name: "PaymentSelection",
  data() {
    const timestamp = dayjs().format("YYYYMMDDHHmmss");
    return {
      // address: '0x9e6EC59ccf72827bCB25097a55a56C2D7F8f894c',
      merchant: "0xad2Bd4AEf28e2869cd935E4B724255DB5cc3B843",
      merchantOrderId: timestamp,
      originalAmt: "2",
      originalCcy: "USD",

      orderId: timestamp,
      shopName: "Shopping Cat",
      goodsName: "College Backpack",
      backUrl: process.env.VUE_APP_BASE_BACKURL,
      payUrl: null,
      isFast: false,
    };
  },
  components: {
    CustomTab,
    BaseFooter,
  },
  created() {
    //初始化调用，先执行
    this.initWallet();
  },
  methods: {
    initWallet() {
      let domain = window.location.host;
      console.log("domain:", domain);
      if (domain == "demo.fastpay.network") {
        this.isFast = true;
        this.url_p = require("../assets/images/payselection_left_pc1.svg");
        this.url_m = require("../assets/images_mobile/04_1.svg");
      } else {
        console.log("there:");
        this.url_m = require("../assets/images_mobile/04.svg");
        this.url_p = require("../assets/images/payselection_left_pc.svg");
        this.isFast = false;
      }
    },

    next() {
      this.$router.push({
        name: "PaymentResult",
      });
    },
    inputAddress() {
      const { merchant, merchantOrderId, originalAmt, originalCcy } = this;
      let _this = this;

      const timestamp = dayjs().format("YYYYMMDDHHmmss");
      this.message({
        merchant,
        merchantOrderId: timestamp,
        originalAmt,
        originalCcy,
        ok(res) {
          console.log(res);
          _this.merchant = res.merchant;
          _this.merchantOrderId = res.merchantOrderId;
          _this.originalAmt = res.originalAmt;
          _this.originalCcy = res.originalCcy;
        },
      });
    },
    Continue() {
      const { merchant, merchantOrderId, originalAmt, originalCcy } = this;

      const orderInfo = {
        merchant,
        merchantOrderId,
        originalAmt,
        originalCcy,
      };

      test(orderInfo).then((res) => {
        console.log(res);
        const { success, result, errorCode, errorMsg } = res;
        if (success) {
          const s = encodeURIComponent(
            window.btoa(
              JSON.stringify({
                merchant,
                merchantOrderId,
                originalAmt,
                originalCcy,
                signature: result,
              })
            )
          );
          window.open(`${process.env.VUE_APP_CASHIER_DESK}/#${s}`, "_blank");
        } else {
          console.error(`${errorCode}:${errorMsg}`);
        }
      });

      /*orderInfo.signature = MD5(str).toString()
      console.log('encodeURIComponent(Base64.encode(JSON.stringify(orderInfo)))------->', encodeURIComponent(Base64.encode(JSON.stringify(orderInfo))))
      const href = `${process.env.VUE_APP_BASE_API}/#${encodeURIComponent(Base64.encode(JSON.stringify(orderInfo)))}`
      window.open(href,'_blank')*/
    },
  },
};
</script>
<style lang="less" scoped></style>
