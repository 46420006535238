<template>
<div class="custom-footer xs-show">
  <div class="custom-footer-left">
    <div class="amount">$ 2.00</div>
    <div class="account">Subtotal (2 items)</div>
  </div>
  <div class="custom-footer-right">
    <div class="continue-btn" @click="next">{{btnTxt}}</div>
  </div>
</div>
</template>

<script>
export default {
  name: 'Footer',
  props: {
    btnTxt: {
      type: String,
      default: ''
    }
  },
  methods: {
    next() {
      this.$emit('handleClickNext')
    }
  }
}
</script>

<style scoped lang="less">
.custom-footer{
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 16px;
  background-color: #fff;
  .custom-footer-left{
    .amount{
      height: 24px;
      margin-bottom: 4px;
      font-family: Roboto-Bold;
      font-weight: Bold;
      font-size: 18px;
      color: #1C1523;
    }
    .account{
      font-family: Roboto-Regular;
      font-weight: 400;
      font-size: 12px;
      color: #575B73;
    }
  }
  .continue-btn{
    width: 200px;
    height: 48px;
    line-height: 48px;
    background: #1C1523;
    border-radius: 12px;
    cursor: pointer;
    font-family: Roboto-Bold;
    font-weight: Bold;
    font-size: 14px;
    text-align: center;
    color: #FFFFFF;
  }
}
</style>
