<template>
  <div class="page-wrap">
    <div class="tab">
      <custom-tab :active="1"/>
    </div>
    <div class="cart-main space-between-wrap">
      <div class="content-left">
        <div class="cart-box reative-wrap">
          <div class="xs-hide">
            <img src="../assets/images/info_left_pc.svg" alt="cart-left">
            <div class="transparent-btn pre-btn" @click="$router.push({name: 'ShoppingCart'})"></div>
            <div class="transparent-btn next-btn" @click="$router.push({name: 'ShippingInfo'})"></div>
          </div>
          <div class="xs-show">
            <img src="../assets/images_mobile/02.svg" alt="cart-left">
          </div>
        </div>
      </div>
      <div class="content-right">
        <div class="reative-wrap">
          <img src="../assets/images/2_4_right.svg" alt="block-puser-card">
        </div>
      </div>
    </div>
    <base-footer @handleClickNext="next"  btnTxt="Continue to Shopping"/>
  </div>
</template>

<script>
import CustomTab from '@/components/CustomTab'
import BaseFooter from '@/components/BaseFooter'
export default {
  name: 'CustomerInfo',
  components: {
    CustomTab,
    BaseFooter
  },
  methods: {
    next() {
      this.$router.push({
        name: 'ShippingInfo'
      })
    }
  }
}
</script>
<style lang="less" scoped>

</style>
