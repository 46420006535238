<template>
  <div class="page-wrap">
    <div class="tab">
      <custom-tab :active="4"/>
    </div>
    <div class="cart-main space-between-wrap">
      <div class="content-left common-wrap32">
        <div class="cart-box reative-wrap mb24">
          <div>
            <div class="result-title mb24">Payment Result</div>
            <div class="time-axis inner-wrap border-wrap" v-if="false">
              <div class="time-axis-item">
                <div class="time-axis-title">Transaction detected</div>
                <div class="time-axis-time">15:23:08 April 01, 2022</div>
              </div>
              <div class="time-axis-item">
                <div class="time-axis-title">Transaction detected</div>
                <div class="time-axis-time">{{ '2022-04-07 11:33:47' | formatShowTime}}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="order-box cart-box reative-wrap mb24">
          <div class="inner-wrap border-wrap">
            <div class="order-title mb24 space-between-wrap">
              <div class="order-title-left">OrderId</div>
              <div class="order-title-right">{{payResult.reqTx}}</div>
            </div>
            <div class="order-detail">
              <div class="order-detail-item mb24 space-between-wrap">
                <span class="item-label">Amount</span>
                <span class="item-value">$ {{payResult.payAmount | money}}</span>
              </div>
              <div class="order-detail-item space-between-wrap">
                <span class="item-label">Date & time</span>
                <span class="item-value">{{ payResult.reqTime | formatShowTime }}</span>
              </div>
              <div class="order-detail-item space-between-wrap">
                <span class="item-label">Crypto requested</span>
                <span class="item-value success" v-if="isSuccess">Complete</span>
                <span class="item-value fail" v-else="isSuccess">Fail</span>
              </div>
            </div>
          </div>
        </div>
        <div class="address-box cart-box reative-wrap mb24">
          <div class="inner-wrap border-wrap">
            <div class="address-title">
              Delivery address
            </div>
            <div class="address-detail">
              <div class="address-box">
                <div class="item-label" style="margin-bottom: 8px">Jamy Larson</div>
                <div class="item-label"> Unit 2 Green Mount Retail Park</div>
                <div class="item-label">Halifax</div>
                <div class="item-label">HX1 5QN</div>
                <div class="item-label">Tel: 0344 332 5931</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="content-right">
        <div class="reative-wrap">
          <img src="../assets/images/info_right_pc.svg" alt="block-puser-card">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CustomTab from '@/components/CustomTab'
var localizedFormat = require('dayjs/plugin/localizedFormat')
dayjs.extend(localizedFormat)
import dayjs from 'dayjs'
import { Base64 } from 'js-base64'
let that;
export default {
  name: 'PaymentResult',
  data() {
    return {
      payResult: {
        code: "", // 0000 成功
        reqTx: "",
        payAmount: "",
        reqTime: ""
      }
    }
  },
  computed: {
    isSuccess() {
      return this.payResult.code === '0000'
    }
  },
  beforeCreate: function () {
    that = this
  },
  created () {
    const params = this.getRequest()
    console.log(params)
    const res = this.getParamsObject(params)
    console.log(res)
    this.payResult = res.order
    this.payResult.code = res.code
    console.log(this.payResult)
  },
  components: {
    CustomTab
  },
  methods: {
    getRequest() {
      let str = location.href
      let num = str.indexOf("#")
      str = str.substr(num + 1)
      return str
    },
    getParamsObject(params) {
      try {
       return JSON.parse(Base64.decode(decodeURIComponent(params)));
      }catch (e) {
        console.log(e)
      }
    },
    formatTime(dateTime, format = 'HH:mm:ss LL') {
      return dayjs(dateTime).format(format)
    }
  },
  filters: {
    formatShowTime (value, format) {
      if (!value) return ''
      return that.formatTime(value, format)
    },
    money (val) {
      val = val.toString().replace(/\$|\,/g,'');
      if(isNaN(val)) {
        val = "0";
      }
      let sign = (val == (val = Math.abs(val)));
      val = Math.floor(val*100+0.50000000001);
      let cents = val%100;
      val = Math.floor(val/100).toString();
      if(cents<10) {
        cents = "0" + cents
      }
      for (var i = 0; i < Math.floor((val.length-(1+i))/3); i++) {
        val = val.substring(0,val.length-(4*i+3))+',' + val.substring(val.length-(4*i+3));
      }
      return (((sign)?'':'-') + val + '.' + cents);
    }
  }
}
</script>
<style lang="less" scoped>
 .result-title {
   height: 28px;
   font-family: Roboto-Medium;
   font-size: 22px;
   color: #1C1523;
   letter-spacing: 0;
   line-height: 28px;
 }
 .time-axis{
   height: 172px;
   .time-axis-item{
     position: relative;
     margin-bottom: 16px;
     padding-left: 28px;
     &:before{
       position: absolute;
       left: 0;
       top: 0;
       content: "";
       width: 12px;
       height: 12px;
       border: 1px solid #32A678;
       border-radius: 12px;
       background-color: #fff;
     }
     &:after{
       content: "";
       position: absolute;
       left: 6px;
       top: 13px;
       background-color: #32A678;
       height: 100%;
       width: 1px;
     }
     &:last-child {
       &:before{
         position: absolute;
         left: 0;
         top: 0;
         content: "";
         width: 12px;
         height: 12px;
         border: 1px solid #32A678;
         border-radius: 12px;
         background-color: #32A678;
       }
       &:after{
         content: "";
         position: absolute;
         left: 6px;
         top: -100%;
         background-color: #32A678;
         height: 100%;
         width: 1px;
       }
     }
   }
   &.success {
     .time-axis-item{
       &:before{
         border: 1px solid #32A678;
       }
       &:after{
         background-color: #32A678;
       }
       &:last-child {
         &:before{
           border: 1px solid #32A678;
           background-color: #32A678;
         }
         &:after{
           background-color: #32A678;
         }
       }
     }
   }
   &.fail {
     .time-axis-item{
       &:before{
         border: 1px solid #F56C6C;
       }
       &:after{
         background-color: #F56C6C;
       }
       &:last-child {
         &:before{
           border: 1px solid #F56C6C;
           background-color: #F56C6C;
         }
         &:after{
           background-color: #F56C6C;
         }
       }
     }
   }
   .time-axis-title{
     margin-bottom: 4px;
     height: 20px;
     font-family: Roboto-Regular;
     font-weight: 400;
     font-size: 14px;
     color: #1C1523;
   }
   .time-axis-time{
     height: 22px;
     font-family: Roboto-Regular;
     font-weight: 400;
     font-size: 12px;
     color: #848AA3;
     letter-spacing: 0;
     line-height: 22px;
   }
 }
 .order-title-left, .address-title{
   height: 20px;
   font-family: Roboto-Medium;
   font-weight: 500;
   font-size: 16px;
   color: #1C1523;
   letter-spacing: 0;
   line-height: 20px;
 }
 .order-title{
   display: flex;
   justify-content: space-between;
   padding-bottom: 24px;
   border-bottom: 1px dashed #E2E3E9;
   .order-title-right {
     height: 22px;
     font-family: Roboto-Regular;
     font-weight: 400;
     font-size: 14px;
     color: #575B73;
     letter-spacing: 0;
     text-align: right;
     line-height: 22px;
   }
 }
 .order-detail-item{
   margin-bottom: 8px;
 }
 .item-label,.item-value{
   font-family: RobotoRegular;
   font-weight: 400;
   font-size: 14px;
   color: #575B73;
   letter-spacing: 0;
   line-height: 22px;
   &.success{
     color: #32A678;
     font-weight: bold;
   }
   &.fail{
     color: #F56C6C;
     font-weight: bold;
   }
 }
 .address-title{
   margin-bottom: 16px;
 }
 .address-box{
   .item-label{
     margin-bottom: 6px;
   }
 }
</style>
